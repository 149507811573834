import React from "react";

const Dashboard = () => {
    return (
        <>
            <div class="header">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-5 col-lg-5 col-md-9 col-sm-9">
                            <nav class="navigation navbar navbar-expand-md navbar-dark ">
                                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarsExample04">
                                    <ul class="navbar-nav mr-auto">
                                        <li class="nav-item active">
                                            <a class="nav-link" href="index.html"> Home  </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#about">About</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#service">Service</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#customer">Customer</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#contact">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col logo_section">
                            <div class="full">
                                <div class="center-desk">
                                    <div class="logo">
                                        <a href="index.html"><img src="images/logo.png" alt="#" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                            <ul class="email">
                                <li><a href="#">Call: (+91) 7973436485</a></li>
                                <li><a href="#">Email: Jardospabeauty@jardospa.com</a></li>
                                <li><a href="#"><i class="fa fa-search" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div id="customer" class="customer">
                <div class="container">
                    {/* <div class="row">
                        <div class="col-md-12">
                            <div class="titlepage">
                                <h2> <img src="images/head.png" alt="#" />Welcome to Jardo spa beauty</h2>
                            </div>
                        </div>
                    </div> */}
                    <div id="myCarousel" class="carousel slide customer_Carousel " data-ride="carousel">
                        <ol class="carousel-indicators">
                            <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                            <li data-target="#myCarousel" data-slide-to="1"></li>
                            <li data-target="#myCarousel" data-slide-to="2"></li>
                        </ol>
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div class="container">
                                    <div class="carousel-caption ">
                                        <div class="test_box">
                                            {/* <i><img src="images/tes.png" alt="#" /></i> */}
                                            <h4>Welcome to Jardo spa beauty</h4>
                                            <span>Our Mission</span>
                                            <p>
                                                Our mission is to provide a peaceful retreat where our clients can experience the highest quality of spa treatments and services in a serene and luxurious setting.</p>
                                            <img src="images/icon.png" alt="#" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="container">
                                    <div class="carousel-caption">
                                        <div class="test_box">
                                            <i><img src="images/tes.png" alt="#" /></i>
                                            <h4>Rohali jonson</h4>
                                            <span>customer</span>
                                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined </p>
                                            <img src="images/icon.png" alt="#" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="container">
                                    <div class="carousel-caption">
                                        <div class="text-bg">
                                            <span>Welcome to</span>
                                            <h1>Jardo spa beauty</h1>
                                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are </p>
                                            <a href="#">Read More </a> <a href="#">Book Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                            {/* <i class="fa fa-chevron-left" aria-hidden="true"></i> */}
                        </a>
                        <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                            {/* <i class="fa fa-chevron-right" aria-hidden="true"></i> */}
                        </a>
                    </div>
                </div>
            </div>
            {/* <section class="banner_main">
                <div id="banner1" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#banner1" data-slide-to="0" class="active"></li>
                        <li data-target="#banner1" data-slide-to="1"></li>
                        <li data-target="#banner1" data-slide-to="2"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="container-fluid">
                                <div class="carousel-caption">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="text-bg">
                                                <span>Welcome to</span>
                                                <h1>Jardo spa beauty</h1>
                                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are </p>
                                                <a href="#">Read More </a> <a href="#">Book Now</a>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="text_img">
                                                <figure><img src="images/girl.png" alt="#" /></figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="container-fluid">
                                <div class="carousel-caption">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="text-bg">
                                                <span>Welcome to</span>
                                                <h1>Jardo spa beauty</h1>
                                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are </p>
                                                <a href="#">Read More </a> <a href="#">Book Now</a>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="text_img">
                                                <figure><img src="images/girl.png" alt="#" /></figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="container-fluid">
                                <div class="carousel-caption">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="text-bg">
                                                <span>Welcome to</span>
                                                <h1>Jardospabeauty</h1>
                                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are </p>
                                                <a href="#">Read More </a> <a href="#">Book Now</a>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="text_img">
                                                <figure><img src="images/girl.png" alt="#" /></figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#banner1" role="button" data-slide="prev">
                        <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    </a>
                    <a class="carousel-control-next" href="#banner1" role="button" data-slide="next">
                        <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                    </a>
                </div>
            </section> */}

            <div id="service" class="service">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="titlepage">
                                <h2> <img src="images/head.png" alt="#" /> Our Services</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div id="hover_chang" class="service_box">
                                <i><img src="images/thr.png" alt="#" /></i>
                                <h3>Massage Therapy</h3>
                                <p>Swedish Massage: A gentle, relaxing massage to relieve stress and improve circulation.
                                    Deep Tissue Massage: Focused on relieving deep muscle tension and chronic pain.
                                    Hot Stone Massage: Uses heated stones to promote deep relaxation and ease muscle stiffness.
                                    Body Treatments</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div id="hover_chang" class="service_box">
                                <i><img src="images/thr1.png" alt="#" /></i>
                                <h3>Body Scrub</h3>
                                <p> Exfoliates and polishes your skin for a smooth and radiant look.
                                    Body Wrap: Detoxifies and nourishes your skin with natural ingredients.
                                    Aromatherapy: Combines the benefits of essential oils with therapeutic massage techniques.
                                    Spa Packages
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div id="hover_chang" class="service_box">
                                <i><img src="images/thr2.png" alt="#" /></i>
                                <h3>Ballenise Therepy</h3>
                                <p>Relaxation Package: Includes a Swedish massage and body scrub.
                                    Rejuvenation Package: Includes a deep tissue massage and body wrap.
                                    Couples Package: Enjoy side-by-side massage therapy with your partner.
                                    Testimonials</p>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <a class="read_more" href="#">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="about" class="about">
                <div class="container">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="titlepage">
                                <h2> <img src="images/head.h.png" alt="#" /> About Our Jardo spa beauty</h2>
                                <p>At Jardo Spa & Beauty, we believe in the power of holistic wellness. Founded in 2023, our spa is dedicated to providing exceptional care and relaxation to our clients.</p>
                                <p>Our mission is to provide a peaceful retreat where our clients can experience the highest quality of spa treatments and services in a serene and luxurious setting..</p>
                                {/* <p>At Jardo Spa & Beauty, we believe in the power of holistic wellness. Founded in 2023, our spa is dedicated to providing exceptional care and relaxation to our clients.</p> */}
                                <a class="read_more">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="customer" class="customer">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="titlepage">
                                <h2> <img src="images/head.png" alt="#" /> What Our Clients Say</h2>
                            </div>
                        </div>
                    </div>
                    <div id="myCarousel" class="carousel slide customer_Carousel " data-ride="carousel">
                        <ol class="carousel-indicators">
                            <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                            <li data-target="#myCarousel" data-slide-to="1"></li>
                            <li data-target="#myCarousel" data-slide-to="2"></li>
                        </ol>
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div class="container">
                                    <div class="carousel-caption ">
                                        <div class="test_box">
                                            {/* <i><img src="images/tes.png" alt="#" /></i> */}
                                            <h4>Rohali jonson</h4>
                                            <span>customer</span>
                                            <p>"My experience at Jardo was absolutely wonderful. The staff is professional, and the treatments are divine. I left feeling completely rejuvenated!" - Happy

                                                "The best spa in town! The atmosphere is so calming, and the services are top-notch. Highly recommend!" - Tarandeep</p>
                                            <img src="images/icon.png" alt="#" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="container">
                                    <div class="carousel-caption">
                                        <div class="test_box">
                                            <i><img src="images/tes.png" alt="#" /></i>
                                            <h4>Rohali jonson</h4>
                                            <span>customer</span>
                                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined </p>
                                            <img src="images/icon.png" alt="#" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="container">
                                    <div class="carousel-caption">
                                        <div class="test_box">
                                            <i><img src="images/tes.png" alt="#" /></i>
                                            <h4>Rohali jonson</h4>
                                            <span>customer</span>
                                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined </p>
                                            <img src="images/icon.png" alt="#" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                            {/* <i class="fa fa-chevron-left" aria-hidden="true"></i> */}
                        </a>
                        <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                            {/* <i class="fa fa-chevron-right" aria-hidden="true"></i> */}
                        </a>
                    </div>
                </div>
            </div>
            <div id="customer" class="customer">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="titlepage">
                                <h2> <img src="images/head.png" alt="#" /> Our Team
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div id="myCarousel" class="carousel slide customer_Carousel " data-ride="carousel">
                        <ol class="carousel-indicators">
                            <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                            <li data-target="#myCarousel" data-slide-to="1"></li>
                            <li data-target="#myCarousel" data-slide-to="2"></li>
                        </ol>
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div class="container">
                                    <div class="carousel-caption ">
                                        <div class="test_box">

                                            <p>
                                                Our team of skilled and certified therapists is passionate about helping you achieve your wellness goals. With extensive training and a commitment to excellence, they ensure every visit is a rejuvenating experience.</p>
                                            <img src="images/icon.png" alt="#" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="container">
                                    <div class="carousel-caption">
                                        <div class="test_box">
                                            <i><img src="images/tes.png" alt="#" /></i>
                                            <h4>Rohali jonson</h4>
                                            <span>customer</span>
                                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined </p>
                                            <img src="images/icon.png" alt="#" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="container">
                                    <div class="carousel-caption">
                                        <div class="test_box">
                                            <i><img src="images/tes.png" alt="#" /></i>
                                            <h4>Rohali jonson</h4>
                                            <span>customer</span>
                                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined </p>
                                            <img src="images/icon.png" alt="#" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                            {/* <i class="fa fa-chevron-left" aria-hidden="true"></i> */}
                        </a>
                        <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                            {/* <i class="fa fa-chevron-right" aria-hidden="true"></i> */}
                        </a>
                    </div>
                </div>
            </div>
            <div id="contact" class="contact">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="titlepage">
                                <h2> <img src="images/head.h.png" alt="#" /> Request <span class="white"> A call Back</span></h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <form id="request" class="main_form">
                                <div class="row">
                                    <div class="col-md-12 ">
                                        <input class="contactus" placeholder="Name" type="type" name="Name" />
                                    </div>
                                    <div class="col-md-12">
                                        <input class="contactus" placeholder="Email" type="type" name="Email" />
                                    </div>
                                    <div class="col-md-12">
                                        <input class="contactus" placeholder="Phone Number" type="type" name="Phone Number" />
                                    </div>
                                    <div class="col-md-12">
                                        <textarea class="textarea" placeholder="Message" type="type" Message="Name">Message </textarea>
                                    </div>
                                    <div class="col-sm-col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <button class="send_btn">Send</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="map-responsive">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.967813079566!2d76.70083337435176!3d30.691181687641812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef30fe0ad9d9%3A0xead39226999307b4!2sJardo%20Spa%20%26%20Beauty!5e0!3m2!1sen!2sin!4v1721888695233!5m2!1sen!2sin" width="600" height="432" frameBorder="0" style={{ border: '0', width: '100%' }} allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer id="contact">
                <div class="footer">
                    <div class="container">
                        {/* <div class="row">
                            <div class="col-md-8 offset-md-4">
                                <form class="bottom_form">
                                    <h3>Newsletter</h3>
                                    <input class="enter" placeholder="Enter your email" type="text" name="Enter your email" />
                                    <button class="sub_btn">subscribe</button>
                                </form>
                            </div>
                        </div> */}
                        <div class="row">

                            <div class="col-md-4 padd_bottom">
                                <div class="heading3">
                                    <a href="#"><img src="images/logo1.png" alt="#" /></a>
                                    <p>Spa therapy refers to a range of treatments that are usually associated with water as in one that includes a hot tub or spa. Today, there are so many wellness treatments that are available at spa centres. All these can be categorised as wellness spa treatments, from body and hair massages to deep facials and nail care.</p>
                                </div>
                            </div>
                            <div class="col-md-4 padd_bottom">

                            </div>
                            <div class="col-md-4 padd_bottom padd_bott">
                                <div class="heading3">
                                    <h3>Contact Us</h3>
                                    <ul class="infometion">
                                        <li><a href="#">Phone: +91 7973436485 / 9780736485</a></li>
                                        <li><a href="#">Email: jardospabeauty@gmail.com</a></li>
                                        <li><a href="#">Address: Jardo Spa, Sector 78, Sohana, near Yes Bank, Mohali-140308</a></li>
                                        <li><a href="#">Hours of Operation

                                            Monday - Sunday: 11:00 AM - 9:00 PM</a></li>
                                        <li><a href="#">
                                            Follow Us: @jardospa</a></li>
                                        <li><a href="#">Book an Appointment: jardospa.in</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="col-xl-6 col-md-12">
                                <div class="row">
                                    <div class="col-md-6 offset-md-1 padd_bottom">
                                        <div class="heading3">
                                            <h3>INFORMATION</h3>
                                            <ul class="infometion">
                                                <li><a href="#">Donec odio. Quisque </a></li>
                                                <li><a href="#">volutpat mattis</a></li>
                                                <li><a href="#">eros.Lorem ipsum dolor</a></li>
                                                <li><a href="#">sit amet, consectetuer  </a></li>
                                                <li><a href="#">adipiscing elit. Donec  </a></li>
                                                <li><a href="#">odio. Quisque volutpat </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="heading3">
                                            <h3>MY ACCOUNT</h3>
                                            <ul class="infometion">
                                                <li><a href="#">Donec odio. Quisque </a></li>
                                                <li><a href="#">volutpat mattis</a></li>
                                                <li><a href="#">eros.Lorem ipsum dolor</a></li>
                                                <li><a href="#">sit amet, consectetuer  </a></li>
                                                <li><a href="#">adipiscing elit. Donec  </a></li>
                                                <li><a href="#">odio. Quisque volutpat </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                    <div class="copyright">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <p>© 2019 All Rights Reserved. <a href="#"> KMA </a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Dashboard;